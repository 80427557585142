<script>

export default {
  data() {
    return {
      currentTimeout: undefined,
    }
  },
  mounted(){
    this.removeZohoWidget()
    var f = document.getElementById("zf_div_6afzfhMTuEB2KJPZagVo7X5M2bchdfYDXGm4uQMe0Eg");
    f.style.border="none";
    f.style.height="720px";
    f.style.transition="all 0.5s ease";
    f.setAttribute("aria-label", 'Sorteo\x20Cluber\x20Port\x20Aventura');
    window.addEventListener('message', function (){
    var evntData = event.data;
    if( evntData && evntData.constructor == String ){
    var zf_ifrm_data = evntData.split("|");
    if ( zf_ifrm_data.length == 2 || zf_ifrm_data.length == 3 ) {
    var zf_perma = zf_ifrm_data[0];
    var zf_ifrm_ht_nw = ( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
    var iframe = document.getElementById("zf_div_6afzfhMTuEB2KJPZagVo7X5M2bchdfYDXGm4uQMe0Eg");
    if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
    var prevIframeHeight = iframe.style.height;
    var zf_tout = false;
    if( zf_ifrm_data.length == 3 ) {
    iframe.scrollIntoView();
    zf_tout = true;
    }
    if ( prevIframeHeight != zf_ifrm_ht_nw ) {
    if( zf_tout ) {
    setTimeout(function(){
    iframe.style.height = zf_ifrm_ht_nw;
    },500);
    } else {
    iframe.style.height = zf_ifrm_ht_nw;
    }
    }
    }
    }
    }
    }, false);
  },
  beforeDestroy(){
    if (this.currentTimeout) clearTimeout(this.currentTimeout)
    $zoho.salesiq.floatbutton.visible("show")
  },
  methods: {
    scrollToForm() {
      document.getElementById('formulario-inscripcion').scrollIntoView({ behavior: 'smooth'})
    },
    removeZohoWidget() {
      if (typeof $zoho === 'undefined') {
        this.currentTimeout = setTimeout(this.removeZohoWidget, 100)
        return
      }
      $zoho.salesiq.floatbutton.visible("hide")
    }
  }
}
</script>

<template>
  <div class="default-page">
    <!-- Texto de Información. -->
    <div class="px-0 container-fluid  centered-layout">
    <div
      id="landing-banner"
      class="w-100 d-flex justify-content-center position-relative"
      :style="{backgroundImage: `url(${require('/public/img/sorteo-portaventura/portaventura_banner.jpeg')})`}"
    >
      <div class="col-lg-10 d-flex px-2 flex-column align-items-center" style="margin-top: 8rem;">
        <h1 class="text-center text-primary" style="font-weight: 900; margin-bottom: 3rem; font-size: 3.5rem;">¡Port Aventura espera a alguien de tu club!</h1>

        <h2 class="text-center" style="margin-bottom: 5rem;">Cluber sortea un viaje inolvidable en familia entre todos sus clientes</h2>

        <div class="alert alert-danger p-3 h5 text-center text-nowrap">
          SORTEO FINALIZADO <b>22/01/2025</b> <wbr />- GANADOR DEL SORTEO: <b>JOSÉ VICENTE COLOMER (7PHV74J)</b> <wbr />- <b>CLUB BALONMANO QUART</b>
        </div>

      </div>
    </div>
    <div class="justify-content-center d-flex p-5" style="padding-top: 5rem !important; padding-bottom: 5rem !important;">
      <div class="col-lg-10 d-flex flex-column flex-md-row flex-nowrap justify-content-around align-items-center">
        <div class="col-md-5">
          <h1 class="mb-4">¿Cómo funciona el sorteo?</h1>
          <p>Cluber pone esta oportunidad a disposición de todos los clubes clientes que lo soliciten y les habilita una campaña en su perfil para que las familias puedan adquirir participaciones mediante un donativo al club (3€).</p>
        </div>
        <div class="col-md-5">
          <img class="px-0 mx-0" :src="require('/public/img/sorteo-portaventura/portaventura_water_ride.jpeg')"/>
        </div>
      </div>

    </div>
    <div class="justify-content-center bg-light d-flex p-5" style="padding-top: 5rem !important; padding-bottom: 5rem !important;">
      <div class="col-lg-8 d-flex flex-column justify-content-around align-items-center">
          <h1 class="mb-4">¿Cómo se beneficia el club?</h1>
          <p class="text-md-center mb-5">Además de dar la oportunidad de ganar el viaje a una de sus familias, el club se financia con la venta de participaciones. El importe íntegro donado por los participantes es para el club (a excepción de los costes de gestión de la transacción).</p>
          <img class="w-100 px-0 col-lg-8" :src="require('/public/img/sorteo-portaventura/portaventura_rollercoaster.jpeg')"/>
      </div>

    </div>
    <div class="justify-content-center d-flex p-5" style="padding-top: 5rem !important; padding-bottom: 5rem !important;">
      <div class="col-lg-10 d-flex flex-column-reverse flex-md-row flex-nowrap justify-content-around align-items-center">
        <div class="col-md-5">
          <img class="px-0 mx-0" :src="require('/public/img/sorteo-portaventura/portaventura_location.jpeg')"/>
        </div>
        <div class="col-md-5">
          <h1 class="mb-4">¿En qué consiste el premio?</h1>
          <p>El ganador disfrutará de un viaje a Port Aventura para cuatro personas (dos adultos y dos niños) que incluye el desplazamiento, las entradas y dos noches de alojamiento.</p>
        </div>
      </div>

    </div>
    <div class="justify-content-center d-flex p-5 bg-light" style="padding-top: 5rem !important; padding-bottom: 5rem !important;">
      <div class="col-lg-10 d-flex flex-column flex-md-row flex-nowrap justify-content-around align-items-center">
        <div class="col-md-5">
          <h1 class="mb-4">¿Cómo participan las familias?</h1>
          <p>Entrando a la campaña del club pueden colaborar con un donativo de 3€. Cada donativo les otorgará una participación y podrán adquirir tantas como deseen.</p>
        </div>
        <div class="col-md-5">
          <img class="px-0 mx-0" :src="require('/public/img/sorteo-portaventura/portaventura_family.jpeg')"/>
        </div>
      </div>

    </div>
    <div id="formulario-inscripcion" class="justify-content-center d-flex py-5" style="padding-top: 3rem !important; padding-bottom: 7rem !important;">
      <div class="col-lg-10 d-flex flex-column align-items-center py-4" >
        <div class="px-5">
          <h1 class="mb-4">Estoy interesado en participar</h1>
          <h4 class="mt-0 mb-5">Déjanos los datos de tu club y nos pondremos en contacto contigo para darte más información</h4>
        </div>
        <iframe style="max-width: 40rem" class="px-2 w-100" src="https://forms.zohopublic.eu/cluber/form/SorteoCluberPortAventura/formperma/6afzfhMTuEB2KJPZagVo7X5M2bchdfYDXGm4uQMe0Eg?zf_rszfm=1" id="zf_div_6afzfhMTuEB2KJPZagVo7X5M2bchdfYDXGm4uQMe0Eg" />


      </div>
    </div>
    </div>
    
  </div>
</template>
<style scoped>
#landing-banner {
  background-position: center;
  background-size: cover;
  padding-top: 7rem;
  padding-bottom: 7rem;
}
#landing-banner::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: rgba(255, 255, 255, 0.87);
}
</style>